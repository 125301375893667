import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { createDataSenderPlaybookConnection, createPlaybook, createTemplatePlaybookConnection, deleteDataSenderPlaybookConnection, deletePlaybook, deleteTemplatePlaybookConnection, getAllPlaybooks, getIndividualPlaybook, Playbook, PlaybookPaginatedList, updateIndividualPlaybook, updateTemplatePlaybookConnection } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  openPlaybookModal: false,
  openCreatePlaybookModal: false,
  openCreatePlaybookKeyMapModal: false,
  successfullyCreatedPlaybook: false,
  successfullyUpdatedPlaybook: false,
  successfullyDeletedPlaybook: false,
  refreshPlaybookList: false,
  successfullyCreatedTemplatePlaybookConnection: false,
  successfullyUpdatedTemplatePlaybookConnection: false,
  successfullyDeletedTemplatePlaybookConnection: false,
  successfullyCreatedDataSenderPlaybookConnection: false,
  successfullyDeletedDataSenderPlaybookConnection: false,
  editingPlaybook: null as Playbook | null,
  paginatedPlaybooks: null as PlaybookPaginatedList | null,
  createdPlaybook: null as Playbook | null,
  selectedPlaybook: null as Playbook | null,
}

const playbooksSlice = createSlice({
  name: 'playbooks',
  initialState,
  reducers: {
    // Selecting playbook reducer
    resetAllPlaybookStates(state) {
      state.loading = false
      state.hasErrors = false
      state.openPlaybookModal = false
      state.openCreatePlaybookModal = false
      state.openCreatePlaybookKeyMapModal = false
      state.successfullyCreatedPlaybook = false
      state.successfullyUpdatedPlaybook = false
      state.successfullyDeletedPlaybook = false
      state.refreshPlaybookList = false
      state.successfullyCreatedTemplatePlaybookConnection = false
      state.successfullyUpdatedTemplatePlaybookConnection = false
      state.successfullyDeletedTemplatePlaybookConnection = false
      state.editingPlaybook = null
      state.paginatedPlaybooks = null
      state.createdPlaybook = null
      state.selectedPlaybook = null
    },
    selectPlaybook(state, { payload }) {
      state.selectedPlaybook = payload;
    },
    removeSelectedPlaybook(state) {
      state.selectedPlaybook = null
    },
    openPlaybookModal(state) {
      state.openPlaybookModal = true;
    },
    closePlaybookModal(state) {
      state.openPlaybookModal = false;
    },
    openCreatePlaybookModal(state) {
      state.openCreatePlaybookModal = true;
    },
    closeCreatePlaybookModal(state) {
      state.openCreatePlaybookModal = false;
    },
    resetTemplatePlaybookConnectionStates(state) {
      state.successfullyCreatedTemplatePlaybookConnection = false;
      state.successfullyDeletedTemplatePlaybookConnection = false;
      state.successfullyUpdatedTemplatePlaybookConnection = false;
    },
    resetPlaybookStates(state) {
      state.successfullyCreatedPlaybook = false;
      state.successfullyDeletedPlaybook = false;
      state.successfullyUpdatedPlaybook = false;
    },
    clearRefreshPlaybookList(state) {
      state.refreshPlaybookList = false;
    },
    resetUpdatedPlaybook(state) {
      state.successfullyUpdatedPlaybook = false;
      state.successfullyCreatedPlaybook = false;
      state.openCreatePlaybookModal = false;
      state.refreshPlaybookList = true;
      state.editingPlaybook = null;
      state.createdPlaybook = null;
    },
    resetCreatedPlaybook(state) {
      state.createdPlaybook = null;
      state.successfullyCreatedPlaybook = false;
      state.openCreatePlaybookModal = false;
      state.refreshPlaybookList = true;
    },
  },
  extraReducers: builder => {
    // Get all Playbooks
    builder.addCase(getAllPlaybooks.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getAllPlaybooks.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.paginatedPlaybooks = payload
      },
    );
    builder.addCase(
      getAllPlaybooks.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Create Playbook
    builder.addCase(createPlaybook.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      createPlaybook.fulfilled,
      (state, { payload }) => {
        if ((payload as any).hasErrors) {
          state.hasErrors = true;
        } else {
          state.hasErrors = false;
          state.loading = false;
          state.createdPlaybook = payload;
          state.successfullyCreatedPlaybook = true;
        }
      },
    );
    builder.addCase(
      createPlaybook.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Get Individual Playbook
    builder.addCase(getIndividualPlaybook.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getIndividualPlaybook.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.openCreatePlaybookModal = true;
        state.editingPlaybook = payload;
      },
    );
    builder.addCase(
      getIndividualPlaybook.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Update Individual Playbook
    builder.addCase(updateIndividualPlaybook.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      updateIndividualPlaybook.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.editingPlaybook = payload;
        state.successfullyUpdatedPlaybook = true;
      },
    );
    builder.addCase(
      updateIndividualPlaybook.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Delete Individual Playbook
    builder.addCase(deletePlaybook.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      deletePlaybook.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.successfullyDeletedPlaybook = true
      },
    );
    builder.addCase(
      deletePlaybook.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Create Template Playbook connection
    builder.addCase(createTemplatePlaybookConnection.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      createTemplatePlaybookConnection.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.refreshPlaybookList = true;
        state.successfullyCreatedTemplatePlaybookConnection = true
      },
    );
    builder.addCase(
      createTemplatePlaybookConnection.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Update Template Playbook connection
    builder.addCase(updateTemplatePlaybookConnection.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      updateTemplatePlaybookConnection.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.refreshPlaybookList = true;
        state.successfullyUpdatedTemplatePlaybookConnection = true
      },
    );
    builder.addCase(
      updateTemplatePlaybookConnection.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Delete Template Playbook connection
    builder.addCase(deleteTemplatePlaybookConnection.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      deleteTemplatePlaybookConnection.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.refreshPlaybookList = true;
        state.successfullyDeletedTemplatePlaybookConnection = true
      },
    );
    builder.addCase(
      deleteTemplatePlaybookConnection.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Create Data Sender Playbook connection
    builder.addCase(createDataSenderPlaybookConnection.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      createDataSenderPlaybookConnection.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.refreshPlaybookList = true;
        state.successfullyCreatedDataSenderPlaybookConnection = true
      },
    );
    builder.addCase(
      createDataSenderPlaybookConnection.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

    // Delete Template Playbook connection
    builder.addCase(deleteDataSenderPlaybookConnection.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      deleteDataSenderPlaybookConnection.fulfilled,
      (state, { meta }) => {
        state.hasErrors = false;
        state.loading = false;
        state.refreshPlaybookList = true;
        const id = meta.arg.playbook_data_sender_id
        const currentEditingPlaybook = state.editingPlaybook;
        const newDataSenders = currentEditingPlaybook!.data_senders.filter(dataSender => dataSender.id !== id)
        state.editingPlaybook = { ...state.editingPlaybook, data_senders: newDataSenders } as any;
        state.successfullyDeletedDataSenderPlaybookConnection = true
      },
    );
    builder.addCase(
      deleteDataSenderPlaybookConnection.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
  }
})

export const {
  openPlaybookModal,
  closePlaybookModal,
  resetTemplatePlaybookConnectionStates,
  resetPlaybookStates,
  openCreatePlaybookModal,
  closeCreatePlaybookModal,
  resetCreatedPlaybook,
  clearRefreshPlaybookList,
  selectPlaybook,
  removeSelectedPlaybook,
  resetUpdatedPlaybook,
  resetAllPlaybookStates
} = playbooksSlice.actions;

export default playbooksSlice.reducer;
