import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { Deal } from '../../Interfaces/deal';
import instance from '../../utils/Axios';

export interface CreateDealProps {
  // Default Props
  customer: number, // Customer Id
  hubspot_owner_id: number, // Current User Id
  home_value: number,
  property_type: string,
  lead_confidence_score:  number,
  listing_link: string,
  conversion_source: string,
  internal_notes: string,
  preferred_appointment_date: string,
  preferred_appointment_time: string,
  // Buyer Props
  loan_officer?: number,
  buyer_commission_model_id?: number,
  buying_price_high?: number,
  buying_price_low?: number,
  buying_market_string?: string,
  // Seller Props
  seller_commission_model_id?: number,
  zestimate?: number,
  listing_address_string?: string,
  // SLA Props
  buyer_number_of_agents?: number,
  buyer_time_to_first_match?: number,
  buyer_time_to_final_match?: number,
  seller_number_of_agents?: number,
  seller_time_to_first_match?: number,
  seller_time_to_final_match?: number,
}

export interface SLALookupRequestProps {
  deal_type: string, // Buy, Sell
  home_value: number,
  address: string, // zipcode
}

export interface SLALookup {
  number_of_agents: number,
  time_to_final_match: number,
  time_to_first_match: number,
}

export interface BuyerSLALookup {
  buyer_number_of_agents?: number,
  buyer_time_to_first_match?: number,
  buyer_time_to_final_match?: number,
}

export interface SellerSLALookup {
  seller_number_of_agents?: number,
  seller_time_to_first_match?: number,
  seller_time_to_final_match?: number,
}

export const CommissionModels = {
  "Flat Fee Model": 1,
  "1% Model": 2,
  "Under 125k Model": 3,
  "Under 75k Model": 4,
  "Buyer No Rebate Model": 5,
  "Traditional Model": 6,
  "Buyer $1K Rebate Model": 7,
  "Buyer 1% Rebate Model": 8,
  "Buyer Tiered Rebate Model": 9,
  "Clever Cash Back (0.5%)": 10,
  "Clever Cash Back ($250)": 11,
  "1.5% Model": 12,
  "Buyer Model (new $250)": 13,
  "Mr Cooper Buyer CCB": 15,
  "Mr Cooper Seller CCB": 16,
  "Gravy Buyer": 17,
}

export const getDealFromId = createAsyncThunk<
  any,
  string | number
>('deal/getDealById', async (dealId) => {
  const response = await instance.get(`deals/${dealId}/`);

  return response.data;
})

export const createDeal = createAsyncThunk<any, CreateDealProps>(
  'deal/createDeal',
  async (deal) => {
    try {
      const response = await instance.post(`deals/create_deal/`, {
        ...deal
      })
  
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedErr: any = err;
      if (typedErr.response.status === 500) {
        return { errors: { '500': 'There was an error creating the deal. Please make sure all fields are filled out correctly' }}
      }
      return { errors: typedErr.response.data }
    }
  }
);

export interface CustomerDealProps {
  vid: string | number,
  page?: number,
}

export interface CustomerDeals {
  count: number,
  next: string,
  previous: string,
  results: Deal[],
}

export const getDealsFromCustomerId = createAsyncThunk<CustomerDeals, CustomerDealProps>(
  'deal/getDealsFromCustomerId',
  async (props) => {
    try {
      const response = await instance.get(`/contacts/deals/${props.vid}/${props.page ? `?page=${props.page}` : ''}`);
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedErr: any = err;
      if (typedErr.response.status === 500) {
        return { errors: { '500': 'There was an error fetching customer deals. Please try again.' }}
      }
      return { errors: typedErr.response.data }
    }
  }
)



export const getCustomerDealsFromURL = createAsyncThunk<CustomerDeals, string>(
  'deal/getCustomerDealsFromURL',
  async (url) => {
    try {
      const response = await instance.get(url);
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedErr: any = err;
      if (typedErr.response.status === 500) {
        return { errors: { '500': 'There was an error fetching customer deals. Please try again.' }}
      }
      return { errors: typedErr.response.data }
    }
  }
)

export const sellerSLALookup = createAsyncThunk<SLALookup | any, SLALookupRequestProps>(
  'deal/sellerSLALookup',
  async (props) => {
    try {
      let params: any = Object.entries(props);
      params = params.map(([k, v]: any) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
      const response = await instance.get(`/deals/sla-template-lookup/?${params}`)
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedErr: any = err;
      if (typedErr.response.status === 500) {
        return { errors: { '500': 'There was an error looking up the sla template. The address may not exist in our database. You can still create the deal.' }}
      }
      return { errors: typedErr.response.data }
    }
  }
)

export const buyerSLALookup = createAsyncThunk<SLALookup | any, SLALookupRequestProps>(
  'deal/buyerSLALookup',
  async (props) => {
    try {
      let params: any = Object.entries(props);
      params = params.map(([k, v]: any) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
      const response = await instance.get(`/deals/sla-template-lookup/?${params}`)
      return response.data;
    } catch (err) {
      Sentry.captureException(err);
      const typedErr: any = err;
      if (typedErr.response.status === 500) {
        return { errors: { '500': 'There was an error looking up the sla template. The address may not exist in our database. You can still create the deal.' }}
      }
      return { errors: typedErr.response.data }
    }
  }
)