/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import Agent from '../../Interfaces/agent';
import Contacts, { LoanOfficer } from '../../Interfaces/contacts';
import Customer from '../../Interfaces/customer';
import { DataSenderObject, getAgentFromId, getAllDataSenders, getContactsFromId, getCustomerFromId, getCustomerHubspotInfo, getLoanOfficerFromId, sendCommunicationEmailToContacts, sendCommunicationSMSToContacts } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  success: false,
  errors: null as any,
  contacts: null as Contacts | null,
  selectedDealId: null as string | number | null,
  successfullySentMessage: false,
  unsuccessfulMessageSend: false,
  sendCount: 0,
  additionalContacts: {} as any,
  customer: null as Customer | null,
  customerHubspotInfo: null as any,
  agent: null as Agent | null,
  loanOfficer: null as LoanOfficer | null,
  successfullyGotAgent: false,
  successfullyGotCustomer: false,
  successfullyGotLoanOfficer: false,
  dataSenders: null as DataSenderObject | any,
};

// --- Use for testing ----
// const hubspotTestInfo = {
//   "hubspot_owner_id": "95151413",
//   "connection_owner": "38242869",
//   "net_revenue": "1000",
//   "lastname": "Soloman",
//   "phone": "+12192265186",
//   "internal_notes": "Agents Eligible for C2C (and why):\n\nAgents To Call and Confirm (and why):\n\nNotes For Hard Sourcing (what’s important to the customer - brokerage, neighborhood, etc):",
//   "hs_analytics_source": "DIRECT_TRAFFIC",
//   "acquisition_channel": "SEO - Clever",
//   "is_in_lead_jail": false,
//   "email": "stephaniesolo@test.com",
//   "text_consent_opt_in": true,
//   "lead_status_id": 17,
//   "agent_notes": "Who is selling: \n\nWhy they’re selling:\n\nTimeframe:\n\nMost Important to seller:\n\nReal Estate Experience:\n\nNotes about the property:\n\nOther important notes:",
//   "is_click_to_claim": "True",
//   "firstname": "Stephanie",
//   "buyer_commission_model": "Buyer 1k Rebate Model",
//   "contact_type": "Customer",
//   "buying_market": "65444",
//   "listing_address": "1501 Locust St, St. Louis, MO 63103, USA",
//   "conversion_source": "AutoHunt",
//   "buying_intent": "Unqualified",
//   "buying_price_high": "1000",
//   "buying_price_low": "100"
// }
// ---- END -----

const communicationsSlice = createSlice({
  name: 'communications',
  initialState,
  reducers: {
    resetErrors(state) {
      state.errors = null;
    },
    resetStateFlags(state) {
      state.successfullySentMessage = false;
      state.unsuccessfulMessageSend = false;
      state.sendCount = 0;
    },
    resetAll(state) {
      state.loading = false;
      state.hasErrors = false;
      state.success = false;
      state.contacts = null;
      state.selectedDealId = null,
      state.successfullySentMessage = false;
      state.unsuccessfulMessageSend = false;
      state.customer = null
      state.agent = null;
      state.loanOfficer = null;
    },
    resetObjectFlags(state) {
      state.successfullyGotLoanOfficer = false;
      state.successfullyGotCustomer = false;
      state.successfullyGotAgent = false;
    }
  },
  extraReducers: builder => {
    // Get Customer from deal id
    builder.addCase(getContactsFromId.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getContactsFromId.fulfilled,
      (state, { payload, meta }) => {
        state.selectedDealId = meta.arg;
        state.hasErrors = false;
        state.loading = false;
        state.success = true;
        state.contacts = payload;
        if (payload.customer) {
          state.additionalContacts.customers = [payload.customer];
        }
        if (payload.loan_officer) {
          state.additionalContacts.loanOfficers = [payload.loan_officer]
        }
        if (payload.agents) {
          state.additionalContacts.agents = [...payload.agents]
        }
        if (payload.cash_buyers) {
          state.additionalContacts.cash_buyers = [...payload.cash_buyers]
        }
      },
    );
    builder.addCase(
      getContactsFromId.rejected,
      (state, {error}) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    // Get Customer
    builder.addCase(getCustomerFromId.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getCustomerFromId.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.success = true;
        state.customer = payload;
        if (state.additionalContacts && state.additionalContacts.customers) {
          state.additionalContacts = {
            ...state.additionalContacts,
            customers: [
              ...state.additionalContacts.customers,
              payload,
            ]
          }
        } else {
          state.additionalContacts = {
            ...state.additionalContacts,
            customers: [
              payload,
            ]
          }
        }
        state.successfullyGotCustomer = true;
      },
    );
    builder.addCase(
      getCustomerFromId.rejected,
      (state, {error}) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    // Get Agent
    builder.addCase(getAgentFromId.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getAgentFromId.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.success = true;
        state.agent = payload;
        if (state.additionalContacts && state.additionalContacts.agents) {
          state.additionalContacts = {
            ...state.additionalContacts,
            agents: [
              ...state.additionalContacts.agents,
              payload,
            ]
          }
        } else {
          state.additionalContacts = {
            ...state.additionalContacts,
            agents: [
              payload,
            ]
          }
        }
        state.successfullyGotAgent = true;
      },
    );
    builder.addCase(
      getAgentFromId.rejected,
      (state, {error}) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    // Get Loan Officer
    builder.addCase(getLoanOfficerFromId.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getLoanOfficerFromId.fulfilled,
      (state, { payload }) => {
        state.loading = false
        if ((payload as any).error) {
          const message = JSON.stringify((payload as any).data);
          state.errors = message;
        } else {
          state.errors = null;
          state.hasErrors = false;
          state.loading = false;
          state.success = true;
          state.loanOfficer = payload;
          if (state.additionalContacts && state.additionalContacts.loanOfficers) {
            state.additionalContacts = {
              ...state.additionalContacts,
              loanOfficers: [
                ...state.additionalContacts.loanOfficers,
                payload,
              ]
            }
          } else {
            state.additionalContacts = {
              ...state.additionalContacts,
              loanOfficers: [
                payload,
              ]
            }
          }
          state.successfullyGotLoanOfficer = true;
        }
      },
    );
    builder.addCase(
      getLoanOfficerFromId.rejected,
      (state, {error}) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(sendCommunicationEmailToContacts.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      sendCommunicationEmailToContacts.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.sendCount = state.sendCount + 1;
        state.loading = false;
        state.success = true;
        state.successfullySentMessage = true;
      },
    );
    builder.addCase(
      sendCommunicationEmailToContacts.rejected,
      (state, {error}) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
        state.unsuccessfulMessageSend = true;
      },
    );
    builder.addCase(sendCommunicationSMSToContacts.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      sendCommunicationSMSToContacts.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.sendCount = state.sendCount + 1;
        state.success = true;
        state.successfullySentMessage = true;
      },
    );
    builder.addCase(
      sendCommunicationSMSToContacts.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
        state.unsuccessfulMessageSend = true;
      },
    );

    // Customer Hubspot Info
    builder.addCase(getCustomerHubspotInfo.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getCustomerHubspotInfo.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.customerHubspotInfo = payload;
      },
    );
    builder.addCase(
      getCustomerHubspotInfo.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );

     // Data Senders
     builder.addCase(getAllDataSenders.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getAllDataSenders.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.hasErrors = true;
          state.errors = payload.data;
        } else {
          state.hasErrors = false;
          state.dataSenders = payload;
        }
      },
    );
    builder.addCase(
      getAllDataSenders.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
  },
});

export const { resetErrors, resetObjectFlags, resetStateFlags, resetAll } = communicationsSlice.actions;

export default communicationsSlice.reducer;
