import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { updateConnection } from './actions';

const initialState = {
  openModal: false,
  loading: false,
  hasErrors: false,
  errors: null,
  successfullyUpdatedConnection: false,
}

const updateConnectionSlice = createSlice({
  name: 'updateConnection',
  initialState,
  reducers: {
    openUpdateModal(state , { payload }) {
      state.openModal = payload;
    },
    resetUpdateState(state) {
      state.successfullyUpdatedConnection = false;
      state.hasErrors = false;
      state.errors = null;
    }
  },
  extraReducers: builder => {
    // add actions
    // UpdateConnection
    builder.addCase(updateConnection.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      updateConnection.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.hasError) {
          state.hasErrors = true;
          state.errors = payload.data.message;
        } else {
          state.hasErrors = false;
          state.successfullyUpdatedConnection = true;
        }
      },
    );
    builder.addCase(
      updateConnection.rejected,
      (state, { error }) => {
        Sentry.captureException(error)
        state.hasErrors = true;
        state.loading = false;
      },
    );
  }
})

export const {
  openUpdateModal,
  resetUpdateState
} = updateConnectionSlice.actions;

export default updateConnectionSlice.reducer;