import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../utils/Axios';

export interface UpdateTemplateObject {
  id: string | number,
  template: TemplateCreationObject
}

export interface TemplateCreationObject {
  context: string[],
  description: string | null,
  name: string,
  plain_template: string | null,
  subject: string,
  tags: string[],
  roles: object,
  template: string,
}

export const getAllTemplates = createAsyncThunk<
  any
>('templates/getAllTemplates', async () => {
  const response = await instance.get(`deals/templates/`);

  return response.data;
})

export const getTemplateById = createAsyncThunk<
  any,
  string | number
>('templates/getTemplateById', async templateId => {
  const response = await instance.get(`deals/templates/${templateId}/`);

  return response.data;
})

export const deleteTemplateById = createAsyncThunk<
  any,
  string | number
>('templates/deleteTemplateById', async templateId => {
  const response = await instance.delete(`deals/templates/${templateId}/`);

  return response.data;
});

export const updateTemplateById = createAsyncThunk<
  TemplateCreationObject,
  UpdateTemplateObject
>('templates/updateTemplateById', async templateObject => {
  
  const response = await instance.patch(`deals/templates/${templateObject.id}/`, {
    ...templateObject.template
  });

  return response.data;
});

export const createTemplate = createAsyncThunk<
  TemplateCreationObject,
  TemplateCreationObject
>('templates/createTemplate', async templateObject => {

  const response = await instance.post(`deals/template/create/`, {
    ...templateObject
  });

  return response.data;
});
