import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/gatsby"
import instance from "../../utils/Axios";

export const updateConnection = createAsyncThunk<any, any>(
  'updateConnection/updateConnection',
  async (data) => {
    try {
      const response = await instance.post(`/deals/receive-status-update/formstack/`, {
        ...data,
      })
      return response.data;
    } catch (error: any) {
      Sentry.captureException(error);
      return { hasError: true, data: error.response.data }
    }
    
  }
)
