// contacts/magic-link/${magicUuid}
// const { access, refresh } = response.data;
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

import { Auth, CognitoUser } from '@aws-amplify/auth';
import MagicLink from '../../Interfaces/magicLink';
const baseURL =
  process.env.GATSBY_ACTIVE_ENV &&
  process.env.GATSBY_ACTIVE_ENV !== 'development'
    ? 'https://api.listwithclever.com'
    : 'http://localhost:8000';
// //
// Async Thunk Actions
// //

const setTokensAndAuthenticate = async (accessToken: string, idToken: string, refreshToken: string) => {
  // Fetch the current config
  const config = Auth.configure();
  // Set the user pool
  const userPool = new CognitoUserPool({
    UserPoolId: config.userPoolId as any,
    ClientId: config.userPoolWebClientId as any,
  });
  // Set the id token
  const cognitoIdToken = new CognitoIdToken({
    IdToken: idToken,
  });
  // set the access token
  const cognitoAccessToken = new CognitoAccessToken({
    AccessToken: accessToken,
  });

  // set the refresh token
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  // get the "username" from the tokens
  const username = cognitoIdToken.payload.email;
  // set the cognito user
  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  // then sign in the user with the generated user session
  user.setSignInUserSession(
    new CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    }),
  );
};

export const getTokensFromMagicLink = createAsyncThunk<
  MagicLink,
  string | number
>('magicLinks/getTokensFromMagicLink', async magicUuid => {
  try {
    const response = await axios.get(
      `${baseURL}/contacts/magic-link/${magicUuid}/`,
    );
    const { access, refresh, id_token } = response.data;

    await setTokensAndAuthenticate(access, id_token, refresh);

    const session = await Auth.currentSession();
    if (session) {
      const accessTokenExpire = session.getAccessToken().getExpiration();
      const refreshToken = session.getRefreshToken();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      // if token has expired refresh it and set authed to true
      if (accessTokenExpire < currentTimeSeconds) {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        if (authenticatedUser) {
          await authenticatedUser.refreshSession(refreshToken);
        }
      }
    }
    // Testing link
    return response.data;
  } catch (e) {
    return {
      error: true,
      type: 'Not Found',
    };
  }
});
