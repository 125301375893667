import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openModal: false,
  createDealModalOpen: false,
}

const addContactsSlice = createSlice({
  name: 'addContacts',
  initialState,
  reducers: {
    openAddContactsModal(state, { payload }) {
      state.openModal = payload
    },
    openCreateDealModal(state, { payload }) {
      state.createDealModalOpen = payload;
    }
  }
})

export const {
  openAddContactsModal,
  openCreateDealModal
} = addContactsSlice.actions;

export default addContactsSlice.reducer;