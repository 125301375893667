import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { combineReducers } from 'redux';
import messagesReducer from './Messages/reducer';
import magicLinksReducer from './MagicLinks/reducer';
import communicationsReducer from './Communications/reducer';
import templatesReducer from './Templates/reducer';
import dealsReducer from './Deal/reducer';
import usersReducer from './User/reducer';
import playbooksReducer from './Playbooks/reducer';
import editingFieldsReducer from './EditingFields/reducer';
import updateConnectionReducer from './UpdateConnection/reducer';
import addContactsReducer from './AddContacts/reducer';

import surveysReducer from './Surveys/reducer';
import dataSenderReducer from './DataSender/reducer';

const rootReducer = combineReducers({
  messages: messagesReducer,
  magicLinks: magicLinksReducer,
  communications: communicationsReducer,
  templates: templatesReducer,
  deals: dealsReducer,
  users: usersReducer,
  playbooks: playbooksReducer,
  editingFields: editingFieldsReducer,
  updateConnection: updateConnectionReducer,
  addContacts: addContactsReducer,
  surveys: surveysReducer,
  dataSender: dataSenderReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type State = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
