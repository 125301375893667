import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { fetchSelf, getUser } from './actions';

const initialState = {
  loading: false,
  user: null as any,
  self: null as any,
  error: false,
}

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getUser.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getUser.fulfilled,
      (state, { payload }) => {
        state.user = payload;
        state.loading = false;
      },
    );
    builder.addCase(
      getUser.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.loading = false;
      },
    );
    builder.addCase(fetchSelf.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSelf.fulfilled, (state, { payload }) => {
      state.self = payload;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(fetchSelf.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  }
})

export default usersSlice.reducer;