import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store';
import '../../config/Cognito';

const ReduxWrapper = ({ element }: any) => (
  <Provider store={store}>
    {element}
  </Provider>
);

export default ReduxWrapper;
