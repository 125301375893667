import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { DataSenderDataModel, DataSenderModel, getCustomerDataSender, getCustomerDataSenderData, sendCustomerDataSender, sendData } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  errors: null,
  successfullySentData: false,
  dataSenderObject: null as DataSenderModel | null,
  dataSenderData: null as DataSenderDataModel | null,
}

const dataSenderSlice = createSlice({
  name: 'dataSender',
  initialState,
  reducers: {
    resetDataStates(state) {
      state.successfullySentData = false;
      state.errors = null,
      state.dataSenderData = null,
      state.dataSenderObject = null;
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(sendData.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      sendData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.hasErrors = true;
          state.errors = payload.messages;
          state.successfullySentData = false;
        } else {
          state.hasErrors = false;
          state.successfullySentData = true;
        }
        
      },
    );
    builder.addCase(
      sendData.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(getCustomerDataSender.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getCustomerDataSender.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.hasErrors = true;
          state.errors = payload.messages;
          state.successfullySentData = false;
        } else {
          state.hasErrors = false;
          state.dataSenderObject = payload;
        }
        
      },
    );
    builder.addCase(
      getCustomerDataSender.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(getCustomerDataSenderData.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getCustomerDataSenderData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.hasErrors = true;
          state.errors = payload.messages;
          state.successfullySentData = false;
        } else {
          state.hasErrors = false;
          state.dataSenderData = payload;
        }
        
      },
    );
    builder.addCase(
      getCustomerDataSenderData.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(sendCustomerDataSender.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      sendCustomerDataSender.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.error) {
          state.hasErrors = true;
          state.errors = payload.messages;
          state.successfullySentData = false;
        } else {
          state.hasErrors = false;
          state.successfullySentData = true;
        }
      },
    );
    builder.addCase(
      sendCustomerDataSender.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
  }
})

export const { resetDataStates, setErrors } = dataSenderSlice.actions;

export default dataSenderSlice.reducer;