import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { createDeal, getDealsFromCustomerId, getDealFromId, CustomerDeals, getCustomerDealsFromURL, sellerSLALookup, buyerSLALookup, SellerSLALookup, BuyerSLALookup, SLALookup } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  errors: null,
  deal: null,
  customerDeals: null as CustomerDeals | null,
  successfullyCreatedDeal: false,
  sellerSLA: null as SellerSLALookup | null,
  buyerSLA: null as BuyerSLALookup | null,
}

const dealSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    resetDealStates(state) {
      state.successfullyCreatedDeal = false;
      state.errors = null
    },
    setErrors(state, { payload }) {
      state.errors = payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getDealFromId.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getDealFromId.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.deal = payload;
      },
    );
    builder.addCase(
      getDealFromId.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(createDeal.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      createDeal.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.errors) {
          state.hasErrors = true;
          state.errors = payload.errors;
        } else {
          state.hasErrors = false;
          state.successfullyCreatedDeal = true;
        }
      },
    );
    builder.addCase(
      createDeal.rejected,
      (state) => {
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(getDealsFromCustomerId.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getDealsFromCustomerId.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.customerDeals = payload;
      },
    );
    builder.addCase(
      getDealsFromCustomerId.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(getCustomerDealsFromURL.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getCustomerDealsFromURL.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        if (state.customerDeals) {
          // combine the list of deals
          const oldCustomerDeals = state.customerDeals;
          oldCustomerDeals.next = payload.next;
          oldCustomerDeals.results.concat(payload.results)
          state.customerDeals = oldCustomerDeals;
        } else {
          state.customerDeals = payload;
        }
        
      },
    );
    builder.addCase(
      getCustomerDealsFromURL.rejected,
      (state) => {
        state.hasErrors = true;
        state.loading = false;
      },
    );
    // SELLER SLA LOOKUP
    builder.addCase(sellerSLALookup.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      sellerSLALookup.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.errors) {
          state.hasErrors = true;
          state.errors = payload.errors;
        } else {
          state.hasErrors = false;
          state.sellerSLA = {
            seller_number_of_agents: (payload as SLALookup).number_of_agents,
            seller_time_to_final_match: (payload as SLALookup).time_to_final_match,
            seller_time_to_first_match: (payload as SLALookup).time_to_first_match,
          };
        }
      },
    );
    builder.addCase(
      sellerSLALookup.rejected,
      (state) => {
        state.hasErrors = true;
        state.loading = false;
      },
    );
    // BUYER SLA LOOKUP
    builder.addCase(buyerSLALookup.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      buyerSLALookup.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        if (payload.errors) {
          state.hasErrors = true;
          state.errors = payload.errors;
        } else {
          state.hasErrors = false;
          state.buyerSLA = {
            buyer_number_of_agents: (payload as SLALookup).number_of_agents,
            buyer_time_to_final_match: (payload as SLALookup).time_to_final_match,
            buyer_time_to_first_match: (payload as SLALookup).time_to_first_match,
          }
        }
      },
    );
    builder.addCase(
      buyerSLALookup.rejected,
      (state) => {
        state.hasErrors = true;
        state.loading = false;
      },
    );
  }
})

export const { resetDealStates, setErrors } = dealSlice.actions;

export default dealSlice.reducer;
