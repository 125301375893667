import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  test: 'test',
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initialState,
  reducers: {},
})

export default messagesSlice.reducer;
