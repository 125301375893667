import { Auth } from '@aws-amplify/auth';

console.log("CONFIGURING");

Auth.configure({
  region: process.env.AWS_AMPLIFY_REGION,
  userPoolId: process.env.AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  mandatorySignIn: true,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});
