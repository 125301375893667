/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('./src/fonts/inter/inter.css');
require('./src/fonts/rubik/rubik.css');
require('./src/fonts/global-fonts.css');

export { default as wrapRootElement } from './src/components/ReduxWrapper';
