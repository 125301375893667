import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import Template, { ParsedTemplateObject } from '../../Interfaces/template';
import { createTemplate, deleteTemplateById, getAllTemplates, updateTemplateById } from './actions';

export const EditingType = {
  SMS: 'SMS',
  Email: 'Email',
  Both: 'Both',
};

const initialState = {
  loading: false,
  hasErrors: false,
  templates: null as Template[] | null,
  editingTemplate: null as Template | null,
  openEditingTemplateModal: false,
  editingType: null as string | null,
  createTemplateSuccess: false,
  deleteTemplateSuccess: false,
  updateTemplateSuccess: false,
  openTemplateListModal: false,
  selectedTemplate: null as Template | null,
  // Playbooks
  openPlaybookTemplateList: false,
  selectedPlaybookTemplate: null as ParsedTemplateObject | null,
}

const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    resetState(state) {
      state.createTemplateSuccess = false;
      state.deleteTemplateSuccess = false;
      state.updateTemplateSuccess = false;
    },
    clearSelectedPlaybookTemplate(state) {
      state.selectedPlaybookTemplate = null;
    },
    selectPlaybookTemplate(state, { payload }) {
      state.selectedPlaybookTemplate = payload;
      // state.openPlaybookTemplateList = !state.openPlaybookTemplateList
    },
    togglePlaybookTemplateList(state) {
      // If closing template remove the selected template
      if (!state.openPlaybookTemplateList) {
        state.selectedTemplate = null;
        state.selectedPlaybookTemplate = null;
      }
      state.openPlaybookTemplateList = !state.openPlaybookTemplateList;
    },
    selectTemplate(state, { payload }) {
      state.selectedTemplate = payload;
      state.openTemplateListModal = !state.openTemplateListModal;
    },
    toggleTemplateListModal(state) {
      state.openTemplateListModal = !state.openTemplateListModal;
    },
    setEditingTemplate(state, { payload }) {
      state.editingTemplate = payload;
    },
    toggleEditingTemplateModal(state) {
      state.openEditingTemplateModal = !state.openEditingTemplateModal;
      state.createTemplateSuccess = false;
    },
    setTemplateType(state, { payload }) {
      state.editingType = payload;
    },
    resetTemplate(state) {
      state.selectedTemplate = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllTemplates.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getAllTemplates.fulfilled,
      (state, { payload }) => {
        state.hasErrors = false;
        state.loading = false;
        state.templates = payload;
      },
    );
    builder.addCase(
      getAllTemplates.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(createTemplate.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      createTemplate.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.createTemplateSuccess = true;
      },
    );
    builder.addCase(
      createTemplate.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(deleteTemplateById.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      deleteTemplateById.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.deleteTemplateSuccess = true;
      },
    );
    builder.addCase(
      deleteTemplateById.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
    builder.addCase(updateTemplateById.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      updateTemplateById.fulfilled,
      (state) => {
        state.hasErrors = false;
        state.loading = false;
        state.createTemplateSuccess = true;
      },
    );
    builder.addCase(
      updateTemplateById.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
  }
})

export const {
  clearSelectedPlaybookTemplate,
  resetTemplate,
  toggleEditingTemplateModal,
  setEditingTemplate,
  setTemplateType,
  toggleTemplateListModal,
  selectTemplate,
  resetState,
  selectPlaybookTemplate,
  togglePlaybookTemplateList
} = templatesSlice.actions;

export default templatesSlice.reducer;
