import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import Agent from '../../Interfaces/agent';
import { LoanOfficer } from '../../Interfaces/contacts';
import Customer from '../../Interfaces/customer';
import { Deal } from '../../Interfaces/deal';
import instance from '../../utils/Axios';

export const patchAgent = createAsyncThunk<Agent|any, any>(
  'editingFields/patchAgent',
  async (agent) => {
    const agentId = agent.id;
    const data = agent.data;
    try {
      const response = await instance.patch(`/deals/agent/${agentId}/`, { ...data })
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err);
      return { hasError: true, data: err.response.data }
    }
  }
)

export const patchDeal = createAsyncThunk<Deal|any, any>(
  'editingFields/patchDeal',
  async (dealObj) => {
    const dealId = dealObj.id;
    const data = dealObj.data;
    try {
      const response = await instance.patch(`/deals/${dealId}/`, { ...data });
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err);
      return { hasError: true, data: err.response.data }
    }
  }
)

export const patchCustomer = createAsyncThunk<Customer|any, any>(
  'editingFields/patchCustomer',
  async (customerObj) => {
    const customerId = customerObj.id;
    const customerData = customerObj.data;
    try {
      const response = await instance.patch(`/contacts/customer/${customerId}/`, { ...customerData });
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err);
      return { hasError: true, data: err.response.data }
    }
  }
)

export const patchLoanOfficer = createAsyncThunk<LoanOfficer|any, any>(
  'editingFields/patchLoanOfficer',
  async (loanOfficerObj) => {
    const loId = loanOfficerObj.id;
    const loData = loanOfficerObj.data;
    try {
      const response = await instance.patch(`/contacts/loan-officer/${loId}/`, { ...loData })
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err);
      return { hasError: true, data: err.response.data }
    }
  }
)