import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import Agent from '../../Interfaces/agent';
import Contacts, { LoanOfficer } from '../../Interfaces/contacts';
import Customer from '../../Interfaces/customer';
import instance from '../../utils/Axios';

export interface EmailCommunicationObject {
  to: string[],
  body: string,
  subject: string,
  cc: string[],
  bcc: string[],
}

export interface SMSCommunicationObject {
  to: string[],
  body: string,
  use_messaging_service: boolean,
  clever_user_identity: string,
}

export interface DataSender {
  id: number,
  request_body: any,
  query_parameters: any,
  name: string,
  security_object_type: string,
  security_object_id: number,
  url: string,
  method: string,
  request_body_format: string,
  created_at: string,
  updated_at: string,
  mortgage_partner: number,
}

export interface DataSenderObject {
  count: number,
  next: string | null,
  previous: string | null,
  results: DataSender[],
}

// //
// Async Thunk Actions
// //
export const getCustomerFromId = createAsyncThunk<
  Customer,
  string | number
>('communications/getCustomerFromId', async customerId => {

  const response = await instance.get(`contacts/customer/${customerId}/`);
  return response.data;
});

export const getCustomerHubspotInfo = createAsyncThunk<any, string|number>(
  'communications/getCustomerHubspotInfo', async (id) => {

    const response = await instance.get(`contacts/hubspot-contact/${id}/`);

    return response.data;
  }
)

export const getAgentFromId = createAsyncThunk<
  Agent,
  string | number
>('communications/getAgentFromId', async agentId => {

  const response = await instance.get(`contacts/api/agent/${agentId}/`);
  return response.data;
});

export const getContactsFromId = createAsyncThunk<
  Contacts,
  string | number
>('communications/getContactsFromId', async dealId => {
  const response = await instance.get(`deals/${dealId}/contacts/`);
  return response.data;
});

export const sendCommunicationEmailToContacts = createAsyncThunk<
  any,
  EmailCommunicationObject
>('communications/sendCommunicationEmailToContacts', async communicationobject => {
  const response = await instance.post(`communications/conversations/email/`, {
    to: communicationobject.to,
    subject: communicationobject.subject,
    body: communicationobject.body,
    cc: communicationobject.cc,
    bcc: communicationobject.bcc,
  })

  return response.data;
})

export const sendCommunicationSMSToContacts = createAsyncThunk<
  any,
  SMSCommunicationObject
>('communications/sendCommunicationSMSToContacts', async communicationobject => {
  const response = await instance.post(`communications/conversations/sms/`, {
    to: communicationobject.to,
    body: communicationobject.body,
    use_messaging_service: communicationobject.use_messaging_service || false,
    clever_user_identity: communicationobject.to.length > 1 ? communicationobject.clever_user_identity || 'Clever Home Concierge' : '',
  });

  return response.data;
})

export const getLoanOfficerFromId = createAsyncThunk<
  LoanOfficer,
  string
>('communications/getLoanOfficerFromId', async id => {
  try {
    const response = await instance.get(`contacts/loan-officer/${id}`)
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error)
    return { error: true, data: error.response.data }
  }
});

export const getAllDataSenders = createAsyncThunk<DataSenderObject | any, number | null>(
  'communications/getAllDataSenders', async (page) => {
    try {
      const response = await instance.get(`contacts/data-senders/${page ? `?page=${page}` : ''}`)
      return response.data;
    } catch (error: any) {
      Sentry.captureException(error)
      return { error: true, data: error.response.data };
    }
  }
)

