import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import User from '../../Interfaces/user';
import instance from '../../utils/Axios';
import { getUserId } from '../../utils/helpers';

export const getUser = createAsyncThunk<
  User
>('users/getUser', async () => {
  const userId = await getUserId()
  const response = await instance.get(`contacts/user/${userId}/`);

  return response.data;
})

export const fetchSelf = createAsyncThunk<Self>('user/fetchSelf', async () => {
  try {
    // check if the object exists in local storage
    if (localStorage.getItem('me')) {
      const parsed = JSON.parse(localStorage.getItem('me') || '');
      if (parsed) {
        return parsed;
      }
    }
    // if no local storage for /me then fetch it
    const response = await instance.get('/contacts/me/');
    localStorage.setItem('me', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return { error };
  }
});
