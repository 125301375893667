/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { submitSurvey } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  success: false,
  error: null,
};

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {
    resetSurvey(state) {
      state.success = false;
      state.loading = false;
      state.hasErrors = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(submitSurvey.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      submitSurvey.fulfilled,
      (state, { payload }) => {
        if (payload.error) {
          state.error = payload.data;
          state.hasErrors = true
        } else {
          state.error = null;
          state.hasErrors = false;
          state.loading = false;
          state.success = true;
        }
      },
    );
    builder.addCase(
      submitSurvey.rejected,
      (state, { error }) => {
        Sentry.captureException(error);
        state.hasErrors = true;
        state.loading = false;
      },
    );
  },
});
export const { resetSurvey } = surveysSlice.actions;
export default surveysSlice.reducer;
