import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { addMinutes } from 'date-fns';
import * as Sentry from "@sentry/gatsby"

const baseURL: string | undefined = process.env.GATSBY_API_URL;

const defaultTagSuggestions = [
  {
    id: 1,
    name: 'agent',
  },
  {
    id: 2,
    name: 'buyer',
  },
  {
    id: 3,
    name: 'sms',
  },
  {
    id: 4,
    name: 'email',
  },
  {
    id: 5,
    name: 'lead_receipt',
  },
  {
    id: 6,
    name: 'seller',
  },
  {
    id: 7,
    name: 'commission_model',
  },
  {
    id: 8,
    name: 'magic_link',
  },
  {
    id: 9,
    name: 'customer',
  },
  {
    id: 10,
    name: 'deal',
  },
  {
    id: 11,
    name: 'intro',
  },
  {
    id: 12,
    name: 'lead_receipt',
  }
]

const getUserId = async () => {

  if (localStorage.getItem('me')) {
    const parsed = JSON.parse(localStorage.getItem('me') || '');
    if (parsed) {
      return parsed.user_id;
    }
  }
};

const getDjangoKeyListFromObject = (type: string, object: any) => {
  if (object === null) {
    return [];
  }

  let keyList = [];
  // Get list of keys from the object
  const keys = Object.keys(object);

  // Map out the keys and determine if they are objects or not
  keyList = keys.map((key) => {
    // If the key is undefined skip it
    if (!(object as any)[key]) {
      return '';
    }

    // If the key is an Array skip it
    if (Array.isArray((object as any)[key])) {
      return '';
    }

    // If the key is not an object we want to include it
    if (typeof object[key] !== 'object') {
      return object[key]
    }
    const individualKeys = Object.keys((object as any)[key]);
    return individualKeys;
  });

  let typeMap = [];
  for (let i = 0; i < keys.length; i += 1) {
    let typeVal = keys[i];
    if (keyList.length > 0) {
      for (let t = 0; t < keyList[i].length; t += 1) {
        // If an object property is also an object we want to skip it
        if ( typeof object[keys[i]][keyList[i][t]] !== 'object') {
          typeMap.push(`${type}.${typeVal}.${keyList[i][t]}`);
        }
      }
    }

    // Prevent us from adding objects to map
    if(typeof object[keys[i]] !== 'object') {
      typeMap.push(`${type}.${keys[i]}`)
    }
  }

  return typeMap;
}


// This took me for freaking EVER to get working correctly.
// Problem occurs because the CKEditor takes a bit of time to "kick" in
const waitForElm = (selector: any) => {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
              resolve(document.querySelector(selector));
              observer.disconnect();
          }
      });

      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}

const isValidPattern = (str: string) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

const isValidURL = (string: string) => {
  if (!isValidPattern(string)) {
    return false;
  }
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

const organizeTemplatesByFolderTag = (templates: any) => {
  const keys = Object.keys(templates);
  const Folders = keys.reduce((result: any, option: any) => {
    const tags = templates[option].tags
    tags.forEach((tag: any) => {
      const lowercasedTag = tag.toLowerCase();
      if (result.indexOf(lowercasedTag) < 0) {
        result.push(lowercasedTag);
      }
    })
    return result;
  }, [] as any)
  let FolderObjects = {} as any;
  Folders.forEach((folderName: any) => {
    keys.forEach((templateKey: any) => {
      const tags = templates[templateKey].tags;
      // If no tags add a NO TAGS FOLDER and add the template
      if (tags.length === 0) {
        if (FolderObjects["NO TAGS"]) {
          FolderObjects["NO TAGS"].push(templates[templateKey])
        } else {
          FolderObjects["NO TAGS"] = [templates[templateKey]]
        }
      }
      const hasTags = tags.filter((tag: any) => tag.toLowerCase() === folderName).length > 0
      if (hasTags) {
        if (FolderObjects[folderName]) {
          FolderObjects[folderName].push(templates[templateKey])
        } else {
          FolderObjects[folderName] = [templates[templateKey]]
        }
      }
    });
  }, [] as any)
  return FolderObjects;
}

const filterTemplateFoldersByString = (templates: any, search: string) => {
  const beforeSearchKeys = Object.keys(templates);
  const lowercaseSearch = search.toLowerCase();
  let filteredTemplates = beforeSearchKeys.reduce((result, option: any) => {
   
    if (lowercaseSearch === "") {
      // return all email templates
      result[option] = templates[option];
    } else if (templates[option].name.toLowerCase().includes(lowercaseSearch)) {
      // Filter by name
      result[option] = templates[option];
    } else if (templates[option].tags.indexOf(lowercaseSearch) > -1) {
      // filter by tags
      result[option] = templates[option];
    } 
    return result;
  }, {} as any)
  const FolderObjects = organizeTemplatesByFolderTag(filteredTemplates);
  return FolderObjects;
}

const filterEmailTemplatesByString = (templates: any[], keys: any[], search: string) => {
  let emailTemplateList = keys.reduce((result, option) => {
    if (search === "") {
      // return all email templates
      result.push({
       ...templates[option]
      });
    } else if (templates[option].tags.indexOf(search) > -1) {
      // filter by tags
      result.push({
        ...templates[option]
      });
    } else if (templates[option].name.includes(search)) {
      // Filter by name
      result.push({
       ...templates[option]
      });
    } else if (templates[option] && templates[option].description && templates[option].description.includes(search)) {
      result.push({
       ...templates[option]
      });
    }
    return result;
  }, [] as any)
  return emailTemplateList;
}

const filterSMSTemplatesByString = (templates: any[], keys: any[], search: string) => {
  let smsTemplateList = keys.reduce((result: any, option: any) => {
    if (search === "" && templates[option].tags.indexOf('sms') > -1) {
      // Reset the templates
      result.push({
        id: templates[option].id,
        name: templates[option].name,
        updated_at: templates[option].updated_at,
        description: templates[option].description,
        templateObject: templates[option]
      });
    } else if (templates[option].tags.indexOf('sms') > -1 && templates[option].tags.indexOf(search) > -1) {
      // search tags
      result.push({
        id: templates[option].id,
        name: templates[option].name,
        updated_at: templates[option].updated_at,
        description: templates[option].description,
        templateObject: templates[option]
      });
    } else if (templates[option].tags.indexOf('sms') > -1 && templates[option].name.includes(search)) {
      // Filter by name
      result.push({
        id: templates[option].id,
        name: templates[option].name,
        updated_at: templates[option].updated_at,
        description: templates[option].description,
        templateObject: templates[option]
      });
    }
    return result
  }, [] as any)

  return smsTemplateList;
}

const checkContactsForSMSOptOut = (template: any, contacts: any[]) => {
  let NOT_OPTED_FLAG = false
  let OPTED_NAMES = '';
  
  if (!template) {
    return {
      notOptedInFlag: NOT_OPTED_FLAG,
      names: OPTED_NAMES,
    }
  }
  const isSMS = template.tags.indexOf('sms') > -1;
  if (isSMS) {
    // Check to see if contact is legit
    if (contacts) {
      contacts.forEach((contactOption: any) => {
        if (Object.keys(contactOption.value).indexOf('sms_opt_out') > -1) {
          if (contactOption.value && contactOption.value.sms_opt_out) {
            NOT_OPTED_FLAG = true;
            OPTED_NAMES += ` ${contactOption.value.firstname}`
          } else if (contactOption.value && contactOption.value.text_consent_opt_in === false) {
            NOT_OPTED_FLAG = true;
            OPTED_NAMES += ` ${contactOption.value.firstname}`
          }
        }
      })
    }
  }
  return {
    notOptedInFlag: NOT_OPTED_FLAG,
    names: OPTED_NAMES,
  }
}

// AUTHENTICATION

const AuthenticationTypes = {
  NotAuthenticated: 'Not Authenticated',
  FetchMagicLink: 'Fetch magic link',
  AlreadyAuthenticated: 'Already Authenticated',
};

const AuthCheck = (magicUuid: string | null, authenticated: boolean) => {
  if (authenticated) {
    return AuthenticationTypes.AlreadyAuthenticated;
  }
  if (magicUuid) {
    return AuthenticationTypes.FetchMagicLink;
  }

  return AuthenticationTypes.NotAuthenticated;
};

const AuthenticationState = {
  NoTokens: 'NoTokens',
  ExpiredRefreshToken: 'ExpiredRefreshToken',
  ExpiredAccessToken: 'ExpiredAccessToken',
  ValidTokens: 'ValidTokens',
  InvalidTokens: 'InvalidTokens',
};

const getAuthenticationStatus = () => {
  if (!Cookies.get('access') && Cookies.get('refresh')) {
    return AuthenticationState.ExpiredAccessToken;
  }
  if (!Cookies.get('access') && !Cookies.get('refresh')) {
    return AuthenticationState.NoTokens;
  }

  const token = Cookies.get('access') as string;
  const refresh = Cookies.get('refresh') as string;
  let decodedToken: { [key: string]: any };
  let decodedRefresh: { [key: string]: any };

  try {
    decodedToken = jwtDecode(token);
    decodedRefresh = jwtDecode(refresh);
  } catch (e) {
    Sentry.captureException(e);
    return AuthenticationState.InvalidTokens;
  }

  if (Date.now() / 1000 > decodedToken.exp) {
    if (Date.now() / 1000 > decodedRefresh.exp) {
      // Token Expired.
      return AuthenticationState.ExpiredRefreshToken;
    }
    // Token can be regenerated so therefore user is still authenticated
    return AuthenticationState.ExpiredAccessToken;
  }
  // Token has not expired and is still valid
  return AuthenticationState.ValidTokens;
};

const RefreshState = {
  SuccessfullyRefreshedTokens: 'SuccessfullyRefreshedTokens',
  ErrorRefreshingTokens: 'ErrorRefreshingTokens',
};

const refreshTokens = async () => {
  const refresh = Cookies.get('refresh');
  try {
    const response = await axios.post(`${baseURL}/contacts/token/refresh/`, {
      refresh,
    });
    const newAccess = response.data.access;
    const newRefresh = response.data.refresh;
    let decodedToken: { [key: string]: any };
    let decodedRefresh: { [key: string]: any };
    try {
      decodedToken = jwtDecode(newAccess);
      decodedRefresh = jwtDecode(newRefresh)
    } catch (e) {
      Sentry.captureException(e);
      const currentDate = new Date();
      const datePlus5Min = addMinutes(currentDate, 5);
      Cookies.set('access', newAccess, { expires: datePlus5Min, sameSite: 'None', secure: true });
      Cookies.set('refresh', newRefresh, { expires: 90, sameSite: 'None', secure: true });
      return RefreshState.SuccessfullyRefreshedTokens;
    }

    Cookies.set('access', newAccess, { expires: new Date(decodedToken.exp * 1000), sameSite: 'None', secure: true });
    Cookies.set('refresh', newRefresh, { expires: new Date(decodedRefresh.exp * 1000), sameSite: 'None', secure: true });
    return RefreshState.SuccessfullyRefreshedTokens;
  } catch (e) {
    Sentry.captureException(e);
    // Error occured within the refresh and could be a race condition
    // Try the refresh check once more
    return RefreshState.ErrorRefreshingTokens;
  }
};

const isAuthenticated = () => {
  const status = getAuthenticationStatus();
  switch (status) {
    case AuthenticationState.NoTokens:
    case AuthenticationState.ExpiredRefreshToken:
      return false;
    default:
      return true;
  }
};

const getAxiosInterceptorConfig = async (config: any) => {
  const updatedConfig = config;
  const status = getAuthenticationStatus();
  switch (status) {
    case AuthenticationState.ExpiredRefreshToken:
    case AuthenticationState.NoTokens:
    case AuthenticationState.InvalidTokens:
      // Invalid tokens let the request fail
      return config;
    case AuthenticationState.ExpiredAccessToken: {
      // Handle Refresh
      let refreshState = await refreshTokens();
      if (refreshState === RefreshState.ErrorRefreshingTokens) {
        // If after retryCount > MAX_RETRY_ATTEMPT and still Error
        // Fail gracefully.
        return config;
      }
      const token = Cookies.get('access');
      updatedConfig.headers.Authorization = `Bearer ${token}`;
      return updatedConfig;
    }
    default: {
      // Valid tokens
      const token = Cookies.get('access');
      updatedConfig.headers.Authorization = `Bearer ${token}`;
      return config;
    }
  }
};

const getUTCDate = (date: Date) => {
  const dtYear = date.getFullYear();
  const dtMonth = date.getMonth();
  const dtDate = date.getDate();
  const ms = Date.UTC(dtYear, dtMonth, dtDate);

  return ms;
}

const convertUTCToLocalDateObject = (dateInt: any) => {
  let date = (!dateInt || dateInt.length < 1) ? new Date() : new Date(dateInt);
  if (typeof dateInt === "string") {
      return date;
  } else {
      const msInMinute = 60000;
      const timezoneTimestamp = date.getTime() + date.getTimezoneOffset() * msInMinute;
      date.setTime(timezoneTimestamp)
      return date;
  }
}

const checkRequiredFields = (config: any[], data: any) => {
  let invalid = false;
  let requiredLabelName = '';
  const requiredFields = config.filter((field: any) => field.required);
  if (requiredFields.length > 0) {
    requiredFields.forEach((field) => {
      if (!data[field.keyName]) {
        invalid = true;
        requiredLabelName = field.label;
      }
    })
  }
  return {
    invalid,
    requiredLabelName,
  }
}

const filterSendThroughKeys = (config: any, data: any) => {
  // Filter out send through keys on the data
  const filteredData = Object.fromEntries(Object.entries(data).filter(([key]) => {
    // get filtered config by keyName
    const filteredConfig = config.filter((configObj: any) => configObj.keyName === key);

    if (filteredConfig.length > 0) {
      // Check if the filtered config has the property send_through if so use it else send it through
      return filteredConfig[0].hasOwnProperty('send_through') ? filteredConfig[0].send_through : true;
    } else {
      return true
    }
  }));
  const newData = Object.assign({}, ...Object.keys(filteredData).map((key) => { return { [key]: data[key] }}));
  return newData;
}

export {
  isAuthenticated,
  defaultTagSuggestions,
  getUserId,
  getDjangoKeyListFromObject,
  waitForElm,
  isValidURL,
  filterEmailTemplatesByString,
  filterSMSTemplatesByString,
  filterTemplateFoldersByString,
  checkContactsForSMSOptOut,
  organizeTemplatesByFolderTag,
  AuthCheck,
  getAxiosInterceptorConfig,
  getAuthenticationStatus,
  AuthenticationState,
  checkRequiredFields,
  convertUTCToLocalDateObject,
  getUTCDate,
  filterSendThroughKeys,
}