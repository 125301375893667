import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import Agent from '../../Interfaces/agent';
import Customer from '../../Interfaces/customer';
import instance from '../../utils/Axios';

export interface DataSenderProps {
  vid: string,
  status: string,
  working_with_clever: boolean,
  notes: string,
  agent_address: string,
  agent_brokerage_name: string,
  agent_city: string,
  agent_email: string,
  agent_name: string,
  agent_phone: string,
  agent_state: string,
  agent_zipcode: string,
}

export interface DataSenderModel {
  id: number,
  request_body: any,
  query_parameters: any,
  name: string,
  security_object_type: string,
  security_object_id: number,
  url: string,
  method: string,
  request_body_format: string,
  created_at: string,
  updated_at: string,
  mortgage_partner: number,
}

export interface HubspotData {
  acquisition_type: string,
  ae_owner: string,
  agent_conversion_source: string,
  agent_home_base: string,
  agent_notes: string,
  agent_profile_link: string,
  agent_status: string,
  agent_tags: string,
  agent_tier: string,
  blanket_referral_agreement_link: string,
  brokerage: string,
  buyer_commission_model: string,
  buyer_is_prequalified: string,
  buyer_showing_time: string,
  buyer_would_like_to_prequalify: string,
  buying_intent: string,
  buying_market: string,
  buying_price_high: string,
  buying_price_low: string,
  closedate: string,
  commission_models_accepted: string,
  company: string,
  connection_owner: string,
  contact_type: string,
  conversion_source: string,
  customer_tags: string,
  email: string,
  expected_list_date: string,
  experiment_segment: string,
  external_vendor_id: string,
  firstname: string,
  gclid: string,
  home_value: string,
  hs_analytics_source: string,
  hs_calculated_phone_number: string,
  hs_lead_status: string,
  hubspot_owner_id: string,
  hunter_id: string,
  hunter_notes: string,
  internal_notes: string,
  is_click_to_claim: string,
  is_cold_mortgage_customer: string,
  is_in_lead_jail: string,
  is_mashvisor: string,
  is_text_opt_out: string,
  lastname: string,
  lead_confidence_score: string,
  lead_jail_notes: string,
  lead_jail_reason: string,
  lead_segment: string,
  lead_type: string,
  lending_bank: string,
  listing_address: string,
  listing_link: string,
  loan_office_preferred_contact_method: string,
  load_officer_tags: string,
  mortgage_partner_lead_status: string,
  needs_sales_support: string,
  net_revenue: string,
  owned_property_in_last_three_years: string,
  phone: string,
  preferred_appointment_date: string,
  preferred_appointment_time: string,
  property_type: string,
  property_usage: string,
  qualifies_for_opendoor_offer: string,
  recent_update: string,
  referral_agreement_status: string,
  seller_commission_model: string,
  showing_address: string,
  special_requests: string,
  text_consent_opt_in: string,
  tm_notes: string,
  unit_number: string,
  vendor: string,
}

export interface DataSenderDataModel {
  hubspot: HubspotData,
  customer: Customer,
  agent: Agent,
}

export const sendData = createAsyncThunk<any, DataSenderProps>(
  'dataSender/sendData',
  async (props) => {
    try {
      const response = await instance.post(`contacts/data-sender/`, {
        ...props
      })
  
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err)
      if (err.data && err.data.messages) {
        return {
          error: true,
          messages: err.data.messages,
        }
      } else {
        return {
          error: true,
          messages: [
            'Contact Data Sender Data does not exist'
          ]
        }
      }
      
    }
  }
);

// GET CUSTOMER DATA SENDER INFO
export const getCustomerDataSender = createAsyncThunk<DataSenderModel | any, number>(
  'dataSender/getCustomerDataSender',
  async (id) => {
    try {
      const response = await instance.get(`contacts/customer/${id}/data-sender/`)
  
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err)
      return {
        error: true,
        messages: [
          'Customer Data Sender does not exist'
        ]
      }
    }
  }
);

// GET CUSTOMER DATA SENDER DATA
export const getCustomerDataSenderData = createAsyncThunk<DataSenderDataModel | any, number>(
  'dataSender/getCustomerDataSenderData',
  async (id) => {
    try {
      const response = await instance.get(`contacts/customer/${id}/data-sender/data/`)
  
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err)
      return {
        error: true,
        messages: [
          'Customer Data Sender Data does not exist'
        ]
      }
    }
  }
);

export const sendCustomerDataSender = createAsyncThunk<DataSenderProps | any, any>(
  'dataSender/sendCustomerDataSender',
  async (props) => {

    try {
      const response = await instance.post(`contacts/customer/${props.vid}/data-sender/send/?data_sender_id=${props.data_sender_id}`, {
        ...props.data
      })
  
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err)
      if (err.data && err.data.messages) {
        return {
          error: true,
          messages: err.data.messages,
        }
      } else {
        return {
          error: true,
          messages: [
            'There was an error sending the request. Please try again, or contact dev support.'
          ]
        }
      }
      
    }
  }
);