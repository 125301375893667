/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { getTokensFromMagicLink } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  success: false,
};

const magicLinksSlice = createSlice({
  name: 'magicLinks',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getTokensFromMagicLink.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      getTokensFromMagicLink.fulfilled,
      (state, { payload }) => {
        state.loading = false;

        if ((payload as any).error) {
          state.hasErrors = true;
          state.success = false;
        } else {
          state.hasErrors = false;
          state.success = true;
        }
      },
    );
    builder.addCase(
      getTokensFromMagicLink.rejected,
      (state, { error }) => {
        state.hasErrors = true;
        state.loading = false;
        // TODO: handle where it goes if fails
        Sentry.captureException(error);
      },
    );
  },
});

export default magicLinksSlice.reducer;
