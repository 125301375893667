import { createSlice } from '@reduxjs/toolkit';
import * as Sentry from "@sentry/gatsby"
import { patchAgent, patchCustomer, patchDeal, patchLoanOfficer } from './actions';

const initialState = {
  loading: false,
  hasErrors: false,
  editingType: null,
  errors: null,
  editingObject: null as any,
  openEditFieldsModal: false,
  successfullyUpdatedField: false,
}

const editingFieldsSlice = createSlice({
  name: 'editingFields',
  initialState,
  reducers: {
    setEditingType(state, { payload }) {
      state.editingType = payload;
    },
    setEditingObject(state, { payload }) {
      state.editingObject = payload;
    },
    setOpenEditFieldsModal(state, { payload }) {
      state.openEditFieldsModal = payload;
    },
    resetEditFieldState(state) {
      state.loading = false;
      state.hasErrors = false;
      state.errors = null;
      state.editingType = null;
      state.editingObject = null;
    },
    resetSuccessState(state) {
      state.successfullyUpdatedField = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(patchAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchAgent.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.hasError) {
        state.errors = payload.data;
        state.hasErrors = true;
      } else {
        state.editingObject = payload;
        state.hasErrors = false;
        state.successfullyUpdatedField = true;
      }
    });
    builder.addCase(patchAgent.rejected, (state, { error }) => {
      Sentry.captureException(error);
      state.hasErrors = true;
    })
    builder.addCase(patchDeal.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchDeal.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.hasError) {
        state.errors = payload.data;
        state.hasErrors = true;
      } else {
        state.editingObject = payload;
        state.hasErrors = false;
        state.successfullyUpdatedField = true;
      }
    });
    builder.addCase(patchDeal.rejected, (state, { error }) => {
      Sentry.captureException(error);
      state.hasErrors = true;
    })
    builder.addCase(patchCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchCustomer.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.hasError) {
        state.errors = payload.data;
        state.hasErrors = true;
      } else {
        state.editingObject = payload;
        state.hasErrors = false;
        state.successfullyUpdatedField = true;
      }
    });
    builder.addCase(patchCustomer.rejected, (state, { error }) => {
      Sentry.captureException(error);
      state.hasErrors = true;
    })

    builder.addCase(patchLoanOfficer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patchLoanOfficer.fulfilled, (state, { payload }) => {
      
      state.loading = false;
      if (payload.hasError) {
        state.errors = payload.data;
        state.hasErrors = true;
      } else {
        state.editingObject = payload;
        state.hasErrors = false;
        state.successfullyUpdatedField = true;
      }
      
    });
    builder.addCase(patchLoanOfficer.rejected, (state, { error }) => {
      Sentry.captureException(error);
      state.hasErrors = true;
    })
  }

});

export const { resetSuccessState, resetEditFieldState, setOpenEditFieldsModal, setEditingType, setEditingObject } = editingFieldsSlice.actions;

export default editingFieldsSlice.reducer;
