import { createAsyncThunk } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/gatsby"
import Template from "../../Interfaces/template";
import instance from "../../utils/Axios";
import { DataSender } from "../Communications/actions";

export interface PlaybookAuthor {
  id: number,
  first_name: string,
  last_name: string,
  email: string,
  is_staff: boolean,
  is_active: boolean,
}


export interface DataSenderPlaybookObject {
  data_sender: DataSender,
  id: number,
  roles_translation: object,
}

export interface Playbook {
  created_at: string,
  created_by: PlaybookAuthor,
  description: string,
  name: string,
  roles: object,
  templates: Template[],
  data_senders: DataSenderPlaybookObject[],
  updated_at: string,
  id: number,
}

export interface PlaybookPaginatedList {
  count: number,
  next: string,
  previous: string,
  results: Playbook[],
}

export interface CreatePlaybookProps {
  description: string,
  name: string,
  roles: object,
}

export interface UpdatePlaybookProps {
  playbook: CreatePlaybookProps,
  id: number,
}

export interface PlaybookTemplateConnectionProps {
  playbook_template_id: number,
  roles_translation: object,
  send_to: string[],
}

export interface CreatePlaybookTemplateConnectionProps {
  playbook_id: number,
  template_id: number,
  roles_translation: object,
  send_to: string[],
}

export interface CreatePlaybookDataSenderConnectionProps {
  playbook_id: number,
  data_sender_id: number,
  roles_translation: object,
}

export interface PlaybookDataSenderDeleteProps {
  playbook_data_sender_id: number,
}

export interface PlaybookTemplateDeleteProps {
  playbook_template_id: number,
  // playbook_id: number,
  // template_id: number,
}

export interface PlaybookTemplateConnection {
  id: number,
  roles_translation: object,
  send_to: string[],
  template: Template
}

export const getAllPlaybooks = createAsyncThunk<PlaybookPaginatedList, number>(
  'playbooks/getAllPlaybooks',
  async (page = 1) => {
    const response = await instance.get(`/communications/playbooks/?page=${page}`)
    return response.data;
  }
)

export const createPlaybook = createAsyncThunk<Playbook, CreatePlaybookProps>(
  'playbooks/createPlaybook',
  async (props) => {
    try {
      const response = await instance.post(`/communications/playbooks/`, {
        ...props,
      });
      return response.data;
    } catch (err: any) {
      Sentry.captureException(err)
      return { hasErrors: true, data: err.response.data }
    }
  }
);

export const getIndividualPlaybook = createAsyncThunk<Playbook, number>(
  'playbooks/getIndividualPlaybook',
  async (id) => {
    const response = await instance.get(`/communications/playbooks/${id}/`)
    return response.data;
  }
)

export const updateIndividualPlaybook = createAsyncThunk<Playbook, UpdatePlaybookProps>(
  'playbooks/updateIndividualPlaybook',
  async (props) => {
    const response = await instance.patch(`/communications/playbooks/${props.id}/`, {
      ...props.playbook
    })

    return response.data;
  }
)

export const deletePlaybook = createAsyncThunk<any, number>(
  'playbooks/deletePlaybook',
  async (id) => {
    const response = await instance.delete(`/communications/playbooks/${id}/`);

    return response.data;
  }
);

export const createTemplatePlaybookConnection = createAsyncThunk<any, CreatePlaybookTemplateConnectionProps>(
  'playbooks/createTemplatePlaybookConnection',
  async (props) => {
    const response = await instance.post(`/communications/template-playbook-connection/`, {
      ...props,
    })

    return response.data;
  }
)

export const updateTemplatePlaybookConnection = createAsyncThunk<any, PlaybookTemplateConnectionProps>(
  'playbooks/updateTemplatePlaybookConnection',
  async (props) => {
    const response = await instance.patch(`/communications/template-playbook-connection/`, {
      ...props,
    });

    return response.data;
  }
)

export const deleteTemplatePlaybookConnection = createAsyncThunk<any, PlaybookTemplateDeleteProps>(
  'playbooks/deleteTemplatePlaybookConnection',
  async (props) => {
    const response = await instance.delete(`/communications/template-playbook-connection/`, {
      data: {
        ...props
      }
    })

    return response.data;
  }
)

// Playbook Data Sender Connection
export const createDataSenderPlaybookConnection = createAsyncThunk<any, CreatePlaybookDataSenderConnectionProps>(
  'playbooks/createDataSenderPlaybookConnection',
  async (props) => {
    const response = await instance.post(`/communications/data-sender-playbook-connection/`, {
      ...props,
    })

    return response.data;
  }
)

// TODO: Add Update for Data sender?

export const deleteDataSenderPlaybookConnection = createAsyncThunk<any, PlaybookDataSenderDeleteProps>(
  'playbooks/deleteDataSenderPlaybookConnection',
  async (props) => {
    const response = await instance.delete(`/communications/data-sender-playbook-connection/`, {
      data: {
        ...props
      }
    })

    return response.data;
  }
)